import React from 'react';
import { isAfter } from 'date-fns';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { Spinner } from 'wix-ui-tpa/Spinner';
import { getStatusTextTranslationKey, StatusBadgeBass } from './StatusBadgeBass';
import { connect } from '../../../common/components/runtime-context';
import { ArrowUpIcon } from './ArrowUpIcon';
import { ArrowDownIcon } from './ArrowDownIcon';
import { TextSubscriptionName } from './TextSubscriptionName';
import {
  getDetails,
  getRegionalSettings,
  isDetailsLoading as isDetailsLoadingSelector,
  isDetailsOpen as isDetailsOpenSelector,
} from '../selectors';
import { TextSubscriptionInfo } from './TextSubscriptionInfo';
import { classes, st } from './SubscriptionsListItemBass.st.css';
import { PAID_PLANS_APP_DEF_ID, STORES_APP_DEF_ID } from '../constants';
import settingsParams from '../settingsParams';
import { RootState } from '../state';
import {
  ActionType,
  Subscription,
  SubscriptionStatus,
  CollectionMethod,
  DurationUnit,
} from '@wix/ambassador-billing-v1-subscription/types';
import { BenefitType } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { isPendingCancellationSubscription, isRecurringSubscription } from '../domainUtils';

const FrequencyTranslations = {
  [DurationUnit.DAY]: 'app.details.price-per-day',
  [DurationUnit.WEEK]: 'app.details.price-per-week',
  [DurationUnit.MONTH]: 'app.details.price-per-month',
  [DurationUnit.YEAR]: 'app.details.price-per-year',
  [DurationUnit.UNKNOWN]: 'app.details.price-undefined',
};

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}

const SubscriptionsListItemBass = ({
  subscription,
  isDetailsOpen,
  isDetailsLoading,
  openDetails,
  closeDetails,
  details,
  language,
  navigateToPricingPlans,
  navigateToStores,
  cancelSubscription,
}: Props & RuntimeProps) => {
  const { t } = useTranslation();
  const settings = useSettings();

  const endDate = subscription.endDate;
  const hasEndDate = Boolean(endDate);
  const validFrom = subscription.startDate;

  const formatDate = (date: string | Date | undefined) => {
    if (date === undefined) {
      return '';
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return date.toLocaleDateString(language, {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const formatMoney = (amount: number | string, currency: string) => {
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString(language, { style: 'currency', currency });
  };

  const isPPSubscription = subscription.origin?.appId === PAID_PLANS_APP_DEF_ID;
  const isStoresSubscription = subscription.origin?.appId === STORES_APP_DEF_ID;

  const navigateToVertical = () => {
    if (isPPSubscription) {
      return navigateToPricingPlans();
    }

    if (isStoresSubscription) {
      return navigateToStores();
    }
  };

  let trialEndDate: Date | undefined;
  let inTrial = false;

  if (subscription.billingSettings?.freeTrialDuration && subscription.startDate) {
    trialEndDate = subscription.billingStatus?.freeTrialData?.endDate;
    if (trialEndDate) {
      inTrial = subscription.status === SubscriptionStatus.ACTIVE && isAfter(trialEndDate, new Date());
    }
  }

  const getFieldA = () => {
    const lines: string[] = [];
    if (subscription.description && subscription.origin?.appId === STORES_APP_DEF_ID) {
      lines.push(subscription.description);
    }
    if (subscription.name) {
      lines.push(subscription.name);
    }
    return lines;
  };

  const getPrice = (subscription: Subscription): number =>
    subscription.items?.reduce(
      (result, item) => result + parseFloat(item?.pricingModel?.fixedPrice?.itemPrice || '0'),
      0,
    ) || 0;

  const getFieldB = () => {
    const lines: string[] = [];

    const price = getPrice(subscription);
    if (price && price > 0) {
      const currency = subscription.billingSettings?.currency;
      const cycleDuration = subscription.billingSettings?.cycleDuration;
      if (price && currency) {
        lines.push(
          isRecurringSubscription(subscription) && cycleDuration
            ? t(FrequencyTranslations[subscription.billingSettings?.cycleDuration?.unit!], {
                price: formatMoney(price, currency),
                count: cycleDuration?.count ?? 1,
              })
            : formatMoney(price, currency),
        );
      }
    } else {
      lines.push(t('app.details.free-plan'));
    }
    if (subscription.billingSettings?.collectionMethod === CollectionMethod.AUTO_CHARGE) {
      if (subscription.billingStatus) {
        const billingStatus = subscription.billingStatus;
        const paymentMethod = subscription.billingSettings.paymentMethod?.id;
        const totalSuccessfulPayments =
          subscription.status === SubscriptionStatus.PENDING && billingStatus.currentCycle === 0
            ? subscription.billingSettings.customBillingSchedule?.cyclesToPayOnCreation || 0
            : billingStatus.currentCycle;

        if (hasEndDate && subscription.billingSettings.totalCycles) {
          lines.push(
            t('app.details.payments-completed-of-total', {
              totalBillingCycles: subscription.billingSettings.totalCycles,
              totalSuccessfulPayments,
            }),
          );
        } else {
          lines.push(t('app.details.payments-completed', { totalSuccessfulPayments }));
        }
        if (billingStatus.previousBillingDate) {
          lines.push(t('app.details.last-payment', { date: formatDate(billingStatus.previousBillingDate) }));
        }
        if (billingStatus.nextBillingDate) {
          lines.push(t('app.details.next-payment', { date: formatDate(billingStatus.nextBillingDate) }));
        }
        if (paymentMethod === 'creditCard') {
          lines.push(t('app.details.payment-method-credit-card'));
        }
        if (paymentMethod === 'payPal') {
          lines.push(t('app.details.payment-method-paypal'));
        }
      }
    }
    if (subscription.billingSettings?.collectionMethod === CollectionMethod.OFFLINE) {
      lines.push(t('app.details.payment-method-offline'));
    }
    return lines;
  };

  const renderFieldC = ({ buyNewPlanText, cancelSubscriptionText }: any) => {
    const hasBuyNewPlanStatus =
      (subscription.status &&
        [SubscriptionStatus.ENDED, SubscriptionStatus.CANCELED].indexOf(subscription.status) > -1) ||
      isPendingCancellationSubscription(subscription);

    const isRecurring = isRecurringSubscription(subscription);
    const isCancelActionAllowed = details?.allowedActions?.filter(
      (action) => action.actionType === (isRecurring ? ActionType.CYCLE_AUTO_RENEW_OFF : ActionType.CANCEL),
    ).length;

    let cta: React.ReactElement | undefined;

    if ((isPPSubscription || isStoresSubscription) && hasBuyNewPlanStatus) {
      cta = (
        <TextButton data-hook="buy-new-plan-button" className={classes.cta} onClick={navigateToVertical}>
          {buyNewPlanText}
        </TextButton>
      );
    }

    if (isCancelActionAllowed && !hasBuyNewPlanStatus) {
      cta = (
        <TextButton data-hook="subscription-cancel-button" className={classes.cta} onClick={cancelSubscription}>
          {cancelSubscriptionText}
        </TextButton>
      );
    }

    if (cta) {
      return <div className={classes.ctaContainer}>{cta}</div>;
    }
    return null;
  };

  const getFieldD = (): string | null => {
    const cancellationDate = subscription.cancellationInfo?.cancellationDate ?? endDate; // endDate is sent as fallback due to backend bug in migrating of SAPI subscriptions.
    switch (subscription.status) {
      case SubscriptionStatus.PENDING:
        return t('app.title.starts', { date: formatDate(validFrom) });
      case SubscriptionStatus.ACTIVE:
        if (isPendingCancellationSubscription(subscription)) {
          return t('app.title.expires', {
            date: formatDate(cancellationDate),
          });
        }
        return hasEndDate ? t('app.title.expires', { date: formatDate(endDate) }) : t('app.title.valid-until-canceled');
      case SubscriptionStatus.PAUSED:
        return null;
      case SubscriptionStatus.ENDED:
        return t('app.title.expired', { date: formatDate(endDate) });
      case SubscriptionStatus.CANCELED:
        return t('app.title.canceled', {
          date: formatDate(cancellationDate),
        });
      default:
        return null;
    }
  };

  const getFieldE = (): string => {
    if (subscription.status === SubscriptionStatus.PENDING) {
      return hasEndDate
        ? t('app.details.expires', { date: formatDate(endDate) })
        : t('app.details.valid-until-canceled');
    }
    return t('app.details.start-date', { date: formatDate(validFrom) });
  };

  const getFieldF = (): string | null =>
    inTrial
      ? t('app.details.trial-ends', { date: formatDate(subscription.billingStatus?.freeTrialData?.endDate!) })
      : null;

  const getFieldG = () => {
    const lines: string[] = [];
    if (details?.benefitBalanceItems?.length) {
      const { benefit } = details.benefitBalanceItems[0];
      if (benefit?.benefitType === BenefitType.LIMITED) {
        const { remainingCredits, totalCredits } = benefit;
        lines.push(t('app.details.sessions-remaining-without-total', { remainingCredits, totalCredits }));
      }
    }
    if (details?.storesSubscription) {
      const { lineItems, shippingInfo } = details.storesSubscription;
      lines.push(t('app.details.quantity', { quantity: lineItems?.[0].quantity }));
      if (shippingInfo?.shipmentDetails?.address) {
        const { address } = shippingInfo.shipmentDetails;
        type AddressType = typeof shippingInfo.shipmentDetails.address;
        const addressLines = ['addressLine1', 'addressLine2', 'city', 'zipCode', 'country']
          .map((field) => address[field as keyof AddressType])
          .filter(Boolean);

        if (addressLines.length) {
          lines.push(t('app.details.shipping-to'));
          addressLines.forEach((line) => lines.push(line as string));
        }
      }
    }
    return lines;
  };

  const fieldA = getFieldA();
  const fieldB = getFieldB();
  const fieldD = getFieldD();
  const fieldE = getFieldE();
  const fieldF = getFieldF();
  const fieldG = getFieldG();

  return (
    <TPAComponentsConsumer>
      {({ mobile }) => (
        <div className={st(classes.root, mobile ? { mobile } : undefined)} data-hook="subscription-list-item">
          <div
            id={`subscription-title-${subscription.id}`}
            className={classes.titleContainer}
            data-hook="subscription-list-item-title"
            onClick={() => (isDetailsOpen ? closeDetails() : openDetails())}
          >
            <div className={classes.lft}>
              <div className={classNames(classes.title, classes.columnA)}>
                {fieldA.map((line, i) => (
                  <div key={i}>
                    <TextSubscriptionName>{line}</TextSubscriptionName>
                  </div>
                ))}
              </div>
              {fieldD && (
                <div className={classes.columnB} data-hook="subscription-date-info">
                  <TextSubscriptionName>{fieldD}</TextSubscriptionName>
                </div>
              )}
              {mobile && (
                <div className={classes.statusBadgeContainer}>
                  <StatusBadgeBass subscription={subscription} />
                </div>
              )}
            </div>
            <div className={classes.rgt}>
              {!mobile && (
                <div className={classes.tooltipWrapper}>
                  <Tooltip content={t(getStatusTextTranslationKey(subscription))} placement="top">
                    <div className={classes.statusBadgeContainer}>
                      <StatusBadgeBass subscription={subscription} />
                    </div>
                  </Tooltip>
                </div>
              )}
              <div className={classes.iconContainer}>
                <button
                  style={{ fontSize: 0 }}
                  aria-expanded={isDetailsOpen ? 'true' : 'false'}
                  aria-label="more details"
                  aria-describedby={`subscription-title-${subscription.id}`}
                >
                  {isDetailsOpen ? (
                    <ArrowUpIcon className={classes.arrow} />
                  ) : (
                    <ArrowDownIcon className={classes.arrow} />
                  )}
                </button>
              </div>
            </div>
          </div>

          {isDetailsOpen && (
            <div className={classes.details} data-hook="subscription-list-item-details">
              <div className={classes.contentContainer}>
                {isDetailsLoading && !details && (
                  <div className={classes.loader} data-hook="subscription-list-item-details-loader">
                    <Spinner />
                  </div>
                )}
                {details && (
                  <>
                    <div className={classes.columnA}>
                      {fieldB.map((line, i) => (
                        <div key={i}>
                          <TextSubscriptionInfo>{line}</TextSubscriptionInfo>
                        </div>
                      ))}
                    </div>
                    <div className={classes.columnB}>
                      <div>
                        <TextSubscriptionInfo>{fieldE}</TextSubscriptionInfo>
                      </div>
                      {fieldF && (
                        <div>
                          <TextSubscriptionInfo>{fieldF}</TextSubscriptionInfo>
                        </div>
                      )}
                      {fieldG.map((line, i) => (
                        <div key={i}>
                          <TextSubscriptionInfo>{line}</TextSubscriptionInfo>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              {details &&
                renderFieldC({
                  buyNewPlanText: settings.get(settingsParams.buyNewPlanText),
                  cancelSubscriptionText: settings.get(settingsParams.cancelSubscriptionText),
                })}
            </div>
          )}
        </div>
      )}
    </TPAComponentsConsumer>
  );
};

const mapRuntimeToProps = (
  state: RootState,
  { subscription }: Props,
  { openDetails, closeDetails, navigateToPricingPlans, navigateToStores, openCancelConfirmModal }: any,
) => {
  const subscriptionId: string = subscription.id!;
  return {
    isDetailsOpen: isDetailsOpenSelector(state, subscriptionId),
    isDetailsLoading: isDetailsLoadingSelector(state, subscriptionId),
    openDetails: () => openDetails(subscriptionId),
    closeDetails: () => closeDetails(subscriptionId),
    details: getDetails(state, subscriptionId),
    language: getRegionalSettings(state),
    navigateToPricingPlans,
    navigateToStores,
    cancelSubscription: () => openCancelConfirmModal(subscriptionId),
  };
};

export default connect(mapRuntimeToProps)(SubscriptionsListItemBass);
